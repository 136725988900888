import { Component, HostBinding, Input } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "app-table",
  standalone: true,
  template: "<ng-content></ng-content>"
})
export class TableComponent {
  @HostBinding("class") class = "table w-full";
  constructor() {}
}

@Component({
  selector: "app-table-row",
  standalone: true,
  template: "<ng-content></ng-content>"
})
export class TableRowComponent {
  @HostBinding("class") class = "table-row bg-white";
  constructor() {}
}

@Component({
  selector: "app-table-header-row",
  standalone: true,
  template: "<ng-content></ng-content>"
})
export class TableHeaderRowComponent {
  @HostBinding("class") class = "table-row";
  constructor() {}
}

@Component({
  selector: "app-table-cell",
  standalone: true,
  template: "<ng-content></ng-content>"
})
export class TableCellComponent {
  @HostBinding("class") class =
    "table-cell p-4 border-b border-gray-200 align-middle";

  constructor() {}
}

@Component({
  selector: "app-table-header-cell",
  standalone: true,
  template: "<ng-content></ng-content>"
})
export class TableHeaderCellComponent {
  @HostBinding("class") class = "table-cell p-4 text-gray-light";

  constructor() {}
}

@Component({
  selector: "app-table-row-group",
  standalone: true,
  template: "<ng-content></ng-content>"
})
export class TableRowGroupComponent {
  @HostBinding("class") class = "table-row-group";

  constructor() {}
}

@Component({
  selector: "app-table-loading-spinner",
  standalone: true,
  imports: [MatProgressSpinnerModule],
  template: `
    <td [colSpan]="colspan" class="py-8">
      <mat-spinner class="mx-auto" [diameter]="20"></mat-spinner>
    </td>
  `
})
export class TableLoadingSpinner {
  @HostBinding("class") class = "table-row";
  @Input() colspan: string;

  constructor() {}
}
